import React, { useState } from "react";
import { IoCloseSharp, IoMenuSharp } from "react-icons/io5";
import { Link } from "react-router-dom";

import logo from "../../resources/logo-transparent.png";

const Navbar = () => {
  let [open, setOpen] = useState(false);

  return (
    <div className=" mr-10  items-end justify-between py-6     md:flex  lg:mx-20   ">
      {/* Navbar title and logo  */}
      <div className="ml-10 flex cursor-pointer  items-start justify-start  lg:ml-0   ">
        <span className="  ">
          <img src={logo} alt="" className=" w-24 object-cover  md:w-32" />
        </span>
      </div>

      {/* Navbar Icon  */}
      <div
        onClick={() => setOpen(!open)}
        className="absolute right-10 top-6 mt-4  cursor-pointer text-3xl transition-all  md:hidden "
      >
        {/* <ion-icon name={open ? "close" : "menu"}></ion-icon> */}
        {open ? <IoCloseSharp /> : <IoMenuSharp />}
      </div>

      <div className=" font-oswald ">
        {/* Navbar Items  */}
        <ul
          className={`lg-px-5 absolute  z-[10] w-full  px-10 pb-12  transition-all  duration-300 ease-in  md:static   md:z-auto md:flex md:items-center md:space-x-1 md:pb-0 lg:space-x-8 
        ${open ? "top-24 opacity-100" : "top-[-800px]"} md:opacity-100  `}
        >
          <li>
            <Link to="/" className="nav-hover-effect " aria-current="page">
              HOME
            </Link>
          </li>

          <li>
            <Link to="/projects" className="nav-hover-effect ">
              PROJECTS
            </Link>
          </li>

          <li>
            <Link to="/about" className="nav-hover-effect ">
              ABOUT US
            </Link>
          </li>

          <li>
            <Link to="/contact" className="nav-hover-effect ">
              CONTACT US
            </Link>
          </li>

          {/* SOCIAL MEDIA ICONS  */}
          <li className="nav-hover-effect">
            <div className="   mt-2 flex cursor-pointer justify-end gap-2 text-xl ">
              <div className="mb-2 flex   gap-3">
                <a
                  href="https://www.facebook.com/profile.php?id=61551461262166"
                  target="_blank"
                  className="nav-social"
                  rel="noreferrer"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="facebook-f"
                    className="w-2.5"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                    ></path>
                  </svg>
                </a>

                <a
                  href="https://www.instagram.com/brownearthdevelopmentsltd?igshid=NzZlODBkYWE4Ng%3D%3D"
                  target="_blank"
                  className=" nav-social "
                  rel="noreferrer"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="instagram"
                    className="w-3.5"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
